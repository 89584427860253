<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="卡号" name="cardNo" class="ui-form__item">
						<a-input v-model:value="formState.cardNo" placeholder="请输入卡号"></a-input>
					</a-form-item>

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>

					<a-form-item label="订单编号" name="businessNo" class="ui-form__item">
						<a-input v-model:value="formState.businessNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>

					<a-form-item label="订单类型" name="businessType" class="ui-form__item">
						<a-select v-model:value="formState.businessType" placeholder="请选择交易类型" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">商城</a-select-option>
							<a-select-option :value="2">卖品</a-select-option>
							<a-select-option :value="3">影票</a-select-option>
							<a-select-option :value="4">演出</a-select-option>
							<a-select-option :value="5">卡券礼包</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="交易时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="userUnionCardOutComeStrategy" :searchData="searchData"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'businessType'">
							{{ ['商品', '卖品', '影票', '演出', '卡券礼包', '一卡通', '特色会员', '充值订单'][record.businessType - 1] }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>用户id：{{ record.userId || '-' }}</div>
							<div>用户昵称：{{ record.userNickname || '-' }}</div>
							<div>用户手机号：{{ record.userPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link">查看订单</a-button><!--  待确定按道理跳转到订单详情  -->
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import exportReport from '@/components/exportReport/exportReport.vue';
	import { getUnionCardFlow } from '@/service/modules/report.js';
	export default {
		components: { exportReport },
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				time: [],
				searchData: {},
				modelRef: {},
				formState: {},
				columns: [{
					title: '卡号',
					dataIndex: 'cardNo',
				}, {
					title: '用户信息', //（用户昵称、所属用户id、用户手机号）
					key: 'userInfo',
				}, {
					title: '订单编号',
					dataIndex: 'businessNo',
				}, {
					title: '订单类型', //（商品、演出、影片、卖品）
					key: 'businessType',
				}, {
					title: '消费金额',
					dataIndex: 'flowPrice',
					width: 100
				}, {
					title: '交易时间',
					key: 'createTime',
				},
        //   {
				// 	title: '操作',
				// 	key: 'action',
				// 	width: 150,
				// 	fixed: 'right'
				// }
        ],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = this.$deepClone(this.formState);
				if (this.time && this.time.length) {
					this.searchData.createStartTime = this.time[0].startOf('days').unix();
					this.searchData.createEndTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getUnionCardFlow({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
